<template>
	<v-app >
	

		<v-snackbar top v-model="snack" :timeout="3000" :color="snackColor">
			{{ snackText }}

			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" text @click="snack = false">{{
						$t("close")
				}}</v-btn>
			</template>
		</v-snackbar>
		<Splash v-if="$store.state.showSplash === true" :showSplash="$store.state.showSplash === true"></Splash>
		
		<v-main class="main" >
		<router-view></router-view>
	</v-main>
		<DialogCard width="400px" :showDialog.sync="showRefreshDialog">
			<template v-slot:title> New Update</template>
			<template v-slot:text>
				There is a new update, please click refresh to update app now.
			</template>
			<template v-slot:actions>
				<v-btn class="btn-confirm" @click="ReloadBrowser()">
					{{ $t("update") }}</v-btn>
				<v-btn class="btn-cancel" @click="showRefreshDialog = false">{{$t("cancel")}}</v-btn>
			</template>
		</DialogCard>
	
	</v-app>
</template>

<script>
import './styles/nanogard.css'
import { bus } from './main'
import axios from 'axios'
import DialogCard from './components/DialogCard.vue'
import Splash from './components/Splash.vue'
import _ from 'lodash'

export default {
	name: 'App',

	created() {
	
		let token = this.$store.getters.getToken
		if ( token !== null && token !== undefined ) {
			axios.defaults.headers.common.Authorization = token
		} else {
			delete axios.defaults.header
		}

		axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

		this.$http.interceptors.response.use(
			( res ) => res,
			( err ) => {
				if( !err.response ){
					console.log( 'this error is probably not REST related...' )
					console.log( err )
					return
				}
				// console.log(err.response);
				// console.log(err.response.status);
				if (
					!_.isNil( err ) &&
					!_.isNil( err.response ) &&
					!_.isNil( err.response.status ) &&
					err.response.status >= 400 &&
					err.response.status < 500
				) {
					//if it is unauthorization, log out user.
					if ( err.response.status === 401 ) {
						bus.$emit(
							'snack-error',
							'Your session has expired, you will be logged out. Please log back in to continue.'
						)
						this.$store.dispatch( 'logout' )
						if ( this.$route.path !== '/login' ) {
							this.$router.push( '/login' )
						}
					} else {
						bus.$emit( 'snack-error', err.response.data )
					}
					// console.log("======== 400 error ==========");
				}
				else if( !err.response && !err.response.status ){
					console.log( 'this error is probably not httml related...' )
					console.log( !err.response )
				} 
				else if (
					( !err.response && err.response.status >= 500 ) ||
					( err.config &&
						!err.config.__isRetryRequest &&
						err.response.status >= 500 )
				) {
					console.log( 'Errorz' )
					console.log( JSON.stringify( err ) )
					console.log( 'Errorz' )
				}
			}
		)
		// this.$store.dispatch( 'setLocale' )
		// let user = this.$store.getters.getUser
		// if ( user ) {
		// 	// this.SetLanguageLocale( this.$store.getters.getUser.locale )
		// 	this.$store.dispatch( 'setLocale', this.$store.getters.getUser.locale )
		// }
		this.$store.dispatch( 'initializeLocale' )
		bus.$on( 'snack-alert', ( data ) => {
			this.snackColor = data.snackColor
			this.snackText = data.snackText
			this.snack = true
		} )
		bus.$on( 'snack-success', ( data ) => {
			this.snackColor = 'success'
			this.snackText = data
			this.snack = true
		} )
		bus.$on( 'snack-error', ( data ) => {
			this.snackColor = 'error'
			this.snackText = data
			this.snack = true
		} )
		bus.$on( 'snack-info', ( data ) => {
			this.snackColor = 'info'
			this.snackText = data
			this.snack = true
		} )
		bus.$on( 'toggle-nav', ( data ) => {
			this.drawer = data
		} )
		bus.$on( 'show-refresh', () => {
			this.showRefreshDialog = true
		} )
		bus.$on( 'update-status', ( data ) => {
			this.$store.state.status = data
		} )
	},
	mounted() { },
	components: {
		Splash,
		DialogCard,
	},

	data() {
		return {
			showRefreshDialog: false,

	
			selectedMenuItem: '',
			
			snackColor: '',
			snackText: '',
			snack: false,
			drawer: false,
		}
	},
	methods: {
		ReloadBrowser(){
			window.location.reload()
		},
		
	
		Logout() {
			this.$store.dispatch( 'logout' )
			bus.$emit( 'snack-info', this.$t( 'snacks.logged_out' ) )
			this.$router.replace( { path: '/' } )
		},
	
		ToggleDrawer() {
			this.drawer = !this.drawer
		},
	
		
		// shouldShowUpdates() {
		// 	let role = this.$store.state.role
		// 	return (
		// 		this.view_updates_roles.some( ( r ) => r === role?.toLowerCase() ) &&
		// 		this.update_descriptions.length > 0
		// 	)
		// },
	},
}
</script>

<style>
.v-application--is-rtl .v-btn {
	letter-spacing: 0 !important;
}

.menu-item:hover {
	background-color: gray;
}

.menu-item:hover>.v-list-item>* {
	/* background-color: red !important; */
	color: white !important;
}

.menu-item:hover>.v-list-item>*>.v-icon {
	/* background-color: red !important; */
	color: white !important;
}
.main{
	background-color: #313538;
	height: 100%;
	overflow: auto;
}
</style>
